import React from 'react';
import { TbRulerMeasure } from "react-icons/tb";
import { VscVmConnect } from "react-icons/vsc";
import { PiBuildingsDuotone } from "react-icons/pi";
import { GrVirtualMachine } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

const ReactiveBusters = () => {
  const { t } = useTranslation();
  
  return (
    <div className="my-12">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        <Buster icon={<GrVirtualMachine />} title={t('services.manufacture_assembly')}/>
        <Buster icon={<PiBuildingsDuotone />} title={t('services.installation')} />
        <Buster icon={<TbRulerMeasure />} title={t('services.measurements')} />
        <Buster icon={<VscVmConnect />} title={t('services.computer_design_drawings')}  />
      </div>
    </div>
  );
};

const Buster = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col justify-start items-center">
      <div className="text-4xl text-black hover: mb-2">{icon}</div>
        <h2 className="text-center text-xl font-semibold mb-2">{title}</h2>
    </div>
  );
};

export default ReactiveBusters;
