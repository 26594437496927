import { useEffect } from 'react';
import { FiMapPin } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
import { FiPhone } from "react-icons/fi";
import AOS from 'aos';
import "aos/dist/aos.css"
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 500 })
    })
    return (
        <div className="flex flex-col max-w-[1400px] px-6 tb-w:px-10 lp-l:px-16 m-auto">
            <h1 className="text-3xl md:text-6xl font-bold py-6 uppercase">{t('contact.info')}</h1>
            <div className="flex flex-col md:flex-row py-6">
                <div className="w-full md:order-2" data-aos="fade-up" data-aos-offest="100">
                    <iframe title="VCS Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2799.5449532217003!2d-73.68420792378765!3d45.43867357107354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc916ede872dfd7%3A0x327e9ca6cff66d27!2sVitrerie%20Chatelle%20%26%20Simard%20Inc!5e0!3m2!1sfr!2sca!4v1708402812973!5m2!1sfr!2sca" width="100%" height="350" style={{ border: 10 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div class="flex flex-col mt-4 md:mt-0 md:w-1/2 md:pr-6 md:order-1" data-aos="fade-up" data-aos-offest="100">
                    <Buster icon={<FiMapPin />} title="565 19e avenue, Lachine, QC, H8S 3S4" />
                    <Buster icon={<TfiEmail />} title="info@chatellesimard.qc.ca" link="mailto:info@chatellesimard.qc.ca?subject=Demande de renseignements" />
                    <Buster icon={<FiPhone />} title="(514) 637-6778" link="tel:514-637-6778" />
                </div>
            </div>
        </div>
    )
}

const Buster = ({ icon, title, link }) => {
    return (
        <div className="text-left flex items-center">
            <div className="text-2xl text-black hover: mb-2 mr-2">{icon}</div>
            {link ?
                <a target="blank" href={link} className="transition ease-in-out hover:text-[#05A5A0] underline flex items-center mb-2 md:h-14 py-2 md:py-0">{title}</a> :
                <p className="flex items-center mb-2 md:h-14 py-2 md:py-0">{title}</p>
            }
        </div>
    );
};

export default Contact;