import React from 'react';
import vcs_building from '../../src/assets/bureau_vcs.jpeg'
import { useTranslation } from 'react-i18next';

const TextImage = ({ imageUrl, altText, overlayText }) => {
  const { t } = useTranslation();
  
  return (
    <div className="w-11/12 md:w-full relative h-[300px] md:hfull flex flex-col md:flex-row items-center">
      <div className="absolute top-2.5 md:top-0 z-10 left-8 md:left-10 flex flex-col items-center w-full md:w-1/2 text-white">
        <h3 className="w-full uppercase text-lg md:text-6xl font-bold leading-tight bg-gray-800 pt-4 pb-2 px-6">{t('mission_statement.title')}</h3>
        <p className="w-full text-sm md:text-base bg-gray-800 pb-4 px-6">{t('mission_statement.mission')}</p>
      </div>
      <img className="md:h-64 object-cover absolute bottom-0 md:z-0 md:right-10 w-full md:w-1/2 border border-gray-800" src={vcs_building} alt="Bureau VCS"/>
    </div>
  );
};

export default TextImage;
