import { useEffect } from 'react';
import HeroBanner from "./HeroBanner";
import TextImage from './TextImage'
import Services from './Services'
import Button from './Button'
import AOS from 'aos';
import "aos/dist/aos.css"

const Homepage = () => {

    useEffect(() => {
        AOS.init({duration: 500})
    })
    return (
        <div>
            <div data-aos="fade-up" data-aos-offest="100">
                <HeroBanner />
            </div>
             <div className="flex flex-col max-w-[1400px] px-6 tb-w:px-10 lp-l:px-16 m-auto">
                <div data-aos="fade-up" data-aos-offest="100">
                    <Services />
                </div>
                <div data-aos="fade-up" data-aos-offest="100">
                   <TextImage />
                </div>
                <div data-aos="fade-up" data-aos-offest="100">
                    <Button />
                </div>
             </div>
        </div>
    )
}

export default Homepage;