import NavBar from './NavBar';

const Header = () => {
    return (
        <div class='w-full'>
            <div className="transition transform translate-y-0 duration-1000">
                <NavBar />
            </div>
        </div>
    )
}

export default Header;