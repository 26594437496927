import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  lng: 'fr',
  resources: {
    fr: {
      translations: require('./fr.json')
    },
    en: {
      translations: require('./en.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['fr', 'en'];

export default i18n;