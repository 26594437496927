const getProjectPhotos1 = () => {
    const images = require.context('./assets/projet1', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos2 = () => {
    const images = require.context('./assets/projet2', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos3 = () => {
    const images = require.context('./assets/projet3', true);
    return images.keys().map(image => images(image));
}
const getProjectPhotos4 = () => {
    const images = require.context('./assets/projet4', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos5 = () => {
    const images = require.context('./assets/projet5', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos6 = () => {
    const images = require.context('./assets/projet6', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos7 = () => {
    const images = require.context('./assets/projet7', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos9 = () => {
    const images = require.context('./assets/projet9', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos10 = () => {
    const images = require.context('./assets/projet10', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos11 = () => {
    const images = require.context('./assets/projet11', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos12 = () => {
    const images = require.context('./assets/projet12', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos13 = () => {
    const images = require.context('./assets/projet13', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos14 = () => {
    const images = require.context('./assets/projet14', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos15 = () => {
    const images = require.context('./assets/projet15', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos16 = () => {
    const images = require.context('./assets/projet16', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos17 = () => {
    const images = require.context('./assets/projet17', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos18 = () => {
    const images = require.context('./assets/projet18', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos19 = () => {
    const images = require.context('./assets/projet19', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos20 = () => {
    const images = require.context('./assets/projet20', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos21 = () => {
    const images = require.context('./assets/projet21', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos22 = () => {
    const images = require.context('./assets/projet22', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos23 = () => {
    const images = require.context('./assets/projet23', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos24 = () => {
    const images = require.context('./assets/projet24', true);
    return images.keys().map(image => images(image));
}

const getProjectPhotos25 = () => {
    const images = require.context('./assets/projet25', true);
    return images.keys().map(image => images(image));
}

const projectsData = [
{
    id: "projet1",
    title: 'Mausolé RSFA',
    photos: getProjectPhotos1(),
},
{
    id: "projet2",
    title: 'Valdev',
    photos: getProjectPhotos2(),
},
{
    id: "projet3",
    title: 'Maison Lavande',
    photos: getProjectPhotos3(),
},
{
    id: "projet4",
    title: '8360 Boulevard Saint-Laurent',
    photos: getProjectPhotos4(),
},
{
    id: "projet5",
    title: 'Centroparc Phase 2',
    photos: getProjectPhotos5(),
},
{
    id: "projet6",
    title: 'Église Copte',
    photos: getProjectPhotos6(),
},
{
    id: "projet7",
    title: 'Édifice Harden',
    photos: getProjectPhotos7(),
},
{
    id: "projet9",
    title: 'Synerca',
    photos: getProjectPhotos9(),
},
{
    id: "projet10",
    title: 'Locatif Henri-Fabre',
    photos: getProjectPhotos10(),
},
{
    id: "projet11",
    title: 'Plaza Côte-des-Neiges',
    photos: getProjectPhotos11(),
},
{
    id: "projet12",
    title: 'Collège Boisbriand',
    photos: getProjectPhotos12(),
},
{
    id: "projet13",
    title: 'Cité Angus',
    photos: getProjectPhotos13(),
},
{
    id: "projet14",
    title: '8400 Bougainville',
    photos: getProjectPhotos14(),
},
{
    id: "projet15",
    title: 'Château Divoire',
    photos: getProjectPhotos15(),
},
{
    id: "projet16",
    title: 'Caisse Desjardins Portugaise',
    photos: getProjectPhotos16(),
},
{
    id: "projet17",
    title: 'Collège Tav',
    photos: getProjectPhotos17(),
},
{
    id: "projet18",
    title: 'Avril Laval',
    photos: getProjectPhotos18(),
},
{
    id: "projet19",
    title: 'ATS',
    photos: getProjectPhotos19(),
},
{
    id: "projet20",
    title: 'Usine Atwater',
    photos: getProjectPhotos20(),
},
{
    id: "projet21",
    title: '1429 de la Gare',
    photos: getProjectPhotos21(),
},
{
    id: "projet22",
    title: 'Whitestone',
    photos: getProjectPhotos22(),
},
{
    id: "projet23",
    title: 'Clinique Saint-Eustache',
    photos: getProjectPhotos23(),
},
{
    id: "projet24",
    title: 'Lyon Leaf',
    photos: getProjectPhotos24(),
},
{
    id: "projet25",
    title: 'Brault & Martineau',
    photos: getProjectPhotos25(),
},
];

export default projectsData;