import logo from '../../assets/vcs_logo.png'
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NavBar = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col">
            <nav className="bg-gray-800 p-4 text-white flex justify-between items-center">
                <NavLink to={"/"}>
                <div className="flex items-center">
                    <img src={logo} alt="Logo" className="w-16" />
                </div>
                </NavLink>
                <div className="flex items-center gap-4">
                    {/* Contact Us link */}
                    <NavLink to={"/projets"} className="text-sm md:text-base text-white">
                        {t('nav.projects')}
                    </NavLink>
                    <NavLink to={"/contact"} className="transition ease-in-out bg-white border border-gray-800 text-black text-sm md:text-base hover:bg-gray-800 hover:border-white hover:text-white duration-200 py-1 px-2 w-fit">
                        {t('nav.contact')}
                    </NavLink>
                </div>
            </nav>
        </div>
    )
}

export default NavBar;