import hero from '../assets/hero.jpg';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const HeroBanner = () => {
    const { t } = useTranslation();

    return (
        <div className="relative aspect-square sm:aspect-video bg-cover bg-center w-full h-full text-black flex items-start">
            <div className="absolute inset-0 opacity-1" style={{ backgroundImage: `url(${hero})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <div className="max-w-[1400px] px-6 tb-w:px-10 lp-l:px-16 m-auto w-full mx-auto z-10 flex flex-col justify-center items-start h-full text-white">
                <h1 className="text-3xl md:text-6xl font-bold leading-tight mb-4 uppercase">{t('hero_banner.title')}</h1>
                <p className="text-sm md:text-base md:w-3/4 mb-4 pr-10">{t('hero_banner.description')}</p>
                <NavLink to={"/projets"} className="transition ease-in-out text-sm md:text-base bg-gray-800 hover:bg-[#05A5A0] text-white py-2 px-4 w-fit duration-200">
                    {t('hero_banner.button')}
                </NavLink>
            </div>
        </div>
    )
}

export default HeroBanner;