import React, { useState, useEffect } from 'react';
import projectsData from '../projectsData';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import AOS from 'aos';
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';


const ProjectGallery = () => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const selectedProject = projectsData[selectedProjectIndex];

  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({duration: 500})
})
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }

  const projectSelector = () => {
    const { innerWidth: width } = window;
    if (width <= 768) {
      return (
        <div className="mt-4 mb-8">
          <Slide slidesToScroll={1} slidesToShow={1}>
            {selectedProject.photos.map((photo, index) => (
              <div style={{ ...divStyle, 'backgroundImage': `url(${photo})` }}>
              </div>
            ))}
          </Slide>
        </div>
      )
    } else {
      return (
      <div className="grid grid-cols-2 gap-4 mt-6 mb-6">
        {selectedProject.photos.map((photo, index) => (
          <img key={index} src={photo} alt={`Project ${selectedProjectIndex + 1} - ${index + 1}`} data-aos="fade-up" data-aos-offest="100" />
        ))}
      </div>
      )
    }
  }

  return (
    <div className="flex flex-col max-w-[1400px] px-6 tb-w:px-10 lp-l:px-16 m-auto">
      <h1 className="text-3xl md:text-6xl font-bold py-6 uppercase">{t('projects.achievements')}</h1>
      <select
        className="w-fit border-b p-2 pl-0 mt-6 mb-6"
        value={selectedProjectIndex}
        onChange={(e) => setSelectedProjectIndex(e.target.value)}
      >
        {projectsData.map((project, index) => (
          <option key={index} value={index}>
            {project.title}
          </option>
        ))}
      </select>
      {projectSelector()}
    </div>
  );
};

export default ProjectGallery;
