import React from 'react';
import logo from '../assets/vcs_logo.png';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (e) => {
      i18n.changeLanguage(e.target.value);
      window.scrollTo({top: 0, behavior: 'smooth'});
  };
  return (
    <footer className="bg-gray-800 py-2 text-center px-4">
      <div class="py-2">
        <img src={logo} alt="Logo" className="w-12 h-12 mx-auto mb-2" />
        <p className="text-white text-[10px]">Vitrerie Chatelle/Simard Inc. © Tous droits réservés 2024 | All Rights Reserved © Vitrerie Chatelle/Simard Inc. 2024</p>
      </div>
      <div className="flex justify-center text-white py-2 border-t">
        <button className="underline" onClick={changeLanguage} value='fr'>Français</button>
        <span className="px-2">|</span>
        <button className="underline" onClick={changeLanguage} value='en'>English</button>
      </div>
    </footer>
  );
};

export default Footer;