import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

const Button = () => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-center my-12">
            <NavLink to={"/contact"} className="transition ease-in-out text-sm md:text-base bg-gray-800 hover:bg-[#05A5A0] text-white py-2 px-4 w-fit duration-200">
                {t('contact.button')}
            </NavLink>
        </div>
    )
}

export default Button;