import React from 'react'
import './App.css';
import { 
  Routes,
  BrowserRouter as Router,
  Route 
} from 'react-router-dom';
import Header from './component/Header/Index';
import Homepage from './component/Homepage';
import Footer from './component/Footer';
import Projects from './component/Projects';
import Contact from './component/Contact'

function App() {
  return (
    <Router>
      <div className='min-h-screen flex flex-col justify-between'>
        <div>
        <Header />
        <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route exact path='/projets' element={<Projects />} />
          <Route exact path='/contact' element={<Contact />} />
        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
